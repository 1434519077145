<template>
  <v-dialog v-model="dialog" max-width="500px">
    <v-card>
      <v-card-title>
        <span class="font-weight-bold h5">Nhập danh sách Sinh viên</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <!-- Excel Upload -->
              <v-file-input
                v-model="form.file"
                accept=".xlsx"
                prepend-icon=""
                label="Tệp Excel"
                hint="Chỉ hỗ trợ .xlsx"
                persistent-hint
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="cancel"> Hủy </v-btn>
        <v-btn color="primary" :loading="loading" @click="submit">
          Nhập danh sách
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import XLSX from "xlsx";

import request from "@/utils/request";

import ServerAutocomplete from "@/components/table/ServerAutocomplete.vue";

export default {
  components: {
    ServerAutocomplete
  },

  props: {
    visible: {
      type: Boolean,
      default: false
    },

    examination: {
      type: Object,
      default: undefined
    },

    subject: {
      type: Object,
      default: undefined
    }
  },

  data() {
    return {
      loading: false,

      form: {
        file: undefined
      }
    };
  },

  computed: {
    dialog: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit("update:visible", val);
      }
    }
  },

  methods: {
    clear() {
      this.form = {
        file: undefined
      };
    },

    cancel() {
      this.clear();
      this.dialog = false;
    },

    async submit() {
      try {
        this.loading = true;

        this.validate();

        const students = await this.readExcel(this.form.file);

        this.validateStudentList(students);

        const transformedStudents = this.transformStudents(students);

        await request({
          url: `examinations/${this.examination.id}/subjects/${this.subject.id}/students/import`,
          method: "POST",
          data: {
            students: transformedStudents
          }
        });

        this.$snackbar("Nhập danh sách sinh viên thành công", "success");

        this.$emit("changed");

        this.dialog = false;
      } catch (error) {
        this.$snackbar(error.message, "error", 10000);
      } finally {
        this.loading = false;
      }
    },

    validate() {
      if (!this.examination) throw new Error("Vui lòng chọn Kíp thi");

      if (!this.subject) throw new Error("Vui lòng chọn Học phần");

      if (!this.form.file) throw new Error("Vui lòng chọn tệp Excel");
    },

    validateStudentList(students) {
      for (const student of students) {
        if (student.courseid !== this.subject.code) {
          throw new Error(
            `Mã học phần trên excel ${student.courseid} không trùng mã học phần được chọn ${this.subject.code}`
          );
        }

        if (student.name !== this.subject.name) {
          throw new Error(
            `Tên học phần trên excel ${student.name} không trùng tên học phần được chọn ${this.subject.name}`
          );
        }

        if (student.termid !== this.examination.termId) {
          throw new Error(
            `Kỳ học trên excel ${student.termid} không trùng kỳ học của Kíp thi được chọn ${this.examination.termId}`
          );
        }
      }
    },

    transformStudents(students) {
      return students.map(s => ({
        classEnrollmentId: s.classEnrollmentID,
        classId: s.classid,
        sectionType: s.SectionType,
        note: s.note,
        numbOrder: s.NumbOrder,
        studentCode: s.StudentID,
        studentName: s.studentname,
        birthdate: s.birthdate,
        groupName: s.groupname,
        academicName: s.AcademicName,
        studyGroupName: s.studygroupname,
        studyGroupId: s.studygroupID,
        email: s.email ? s.email.trim().toLowerCase() : ""
      }));
    },

    readExcel(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = function(e) {
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: "array" });

          const firstWorkSheet = Object.values(workbook.Sheets)[0];

          const json = XLSX.utils.sheet_to_json(firstWorkSheet, { raw: false });

          resolve(json);
        };

        reader.onerror = function(e) {
          reject(e);
        };

        reader.readAsArrayBuffer(file);
      });
    }
  }
};
</script>
