<template>
  <v-container
    v-if="examination"
    style="height: calc(100vh - 60px); overflow-y: auto"
    fluid
  >
    <SimpleTable
      ref="dataTable"
      :subtitle="`${examination.name} - ${examination.termId}`"
      :url="
        subject
          ? `/examinations/${examination.id}/subjects/${subject.id}/students`
          : undefined
      "
      :headers="headers"
      creatable
      editable
      removable
      item-name="Sinh viên"
      class="elevation-1"
    >
      <template v-slot:actions>
        <v-select
          v-model="subject"
          return-object
          hide-details
          label="Học phần"
          item-text="fullName"
          :items="examination.subjects"
        />

        <v-spacer />

        <v-btn
          color="error"
          class="mb-2 mr-2"
          @click="deleteAllStudents"
          :disabled="!subject"
        >
          Xóa danh sách
        </v-btn>

        <v-btn
          color="primary"
          class="mb-2 mr-2"
          @click="importStudentDialog = true"
          :disabled="!subject"
        >
          Nhập danh sách
        </v-btn>
      </template>
    </SimpleTable>

    <!-- Import Students List -->
    <ImportStudentDialog
      :examination="examination"
      :subject="subject"
      :visible.sync="importStudentDialog"
      @changed="refreshData"
    />
  </v-container>
</template>

<script>
import SimpleTable from "@/components/table/SimpleTable.vue";
import ImportStudentDialog from "./ImportStudentDialog.vue";
import request from "@/utils/request";
import dayjs from "dayjs";

export default {
  components: {
    SimpleTable,
    ImportStudentDialog
  },

  data() {
    return {
      importStudentDialog: false,

      examination: undefined,

      subject: undefined,

      headers: [
        {
          text: "Mã sinh viên",
          value: "studentCode",
          rules: "required"
        },
        {
          text: "Tên sinh viên",
          value: "studentName",
          rules: "required"
        },
        {
          text: "Mã lớp",
          value: "classId",
          rules: "required"
        },
        {
          text: "Tên Nhóm học",
          value: "studyGroupName",
          rules: "required"
        },
        {
          text: "Ngày sinh",
          value: "birthdate"
        },
        {
          text: "Số thự tự",
          value: "numbOrder"
        },
        {
          text: "Mã lớp đăng ký",
          value: "classEnrollmentId"
        },
        {
          text: "Loại lớp",
          value: "sectionType"
        },
        {
          text: "Ghi chú",
          value: "note"
        },
        {
          text: "Nhóm",
          value: "groupName"
        },
        {
          text: "Viện",
          value: "academicName"
        },
        {
          text: "Mã Nhóm học",
          value: "studyGroupId"
        },
        {
          text: "Email",
          value: "email",
          rules: "email"
        }
      ]
    };
  },

  created() {
    this.init();
  },

  methods: {
    init() {
      this.fetchExamination();
    },

    async fetchExamination() {
      try {
        if (!this.$route.params.id) return;

        const res = await request({
          url: "/examinations/" + this.$route.params.id
        });

        this.examination = res.data;

        this.subject = this.examination.subjects[0];
      } catch (error) {
        console.error(error);
        this.$snackbar(error.message, "error");
      }
    },

    refreshData() {
      this.$refs.dataTable.fetchData();
    },

    async deleteAllStudents() {
      try {
        const confirm = await this.$confirm(
          "Bạn có chắc chắn muốn xóa tất cả danh sách sinh viên?",
          {
            title: "Xác nhận xóa",
            buttonTrueText: "Xóa",
            buttonFalseText: "Hủy"
          }
        );

        if (!confirm) return;

        await request({
          method: "DELETE",
          url: `/examinations/${this.examination.id}/subjects/${this.subject.id}/students`
        });

        this.$snackbar("Xóa toàn bộ danh sách thành công", "success");

        this.refreshData();
      } catch (error) {
        console.error(error);
        this.$snackbar(error.message, "error");
      }
    },

    localDate(d) {
      return dayjs(d).format("DD/MM/YYYY");
    }
  }
};
</script>
